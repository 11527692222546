import React from "react";
import PropTypes from "prop-types";
import Masonry from 'react-masonry-css';
import Pagination from "../components/pagination";
import Layout from "../components/layout";
import Article from "../components/article";
import "../css/masonry.css";

const ListTemplate = ({pageContext, location}) => {
    let {indexed, posts, page, totalItems, uris, section, subsection, postsPerPage, homeFeaturedPosts, paginationItems, categories} = pageContext;
    const breakpointColumnsObj = {
        default: 3,
        1100: 2,
        700: 1
    };
    
    subsection = subsection || '';
    
    return (
        <Layout 
            homeFeaturedPosts={homeFeaturedPosts} 
            indexedImages={indexed} 
            uris={uris} 
            page={page}
            section={section}
            subsection={subsection}
            categories={categories}
            location={location}
        >
            <div className="row masonry-wrap">
                <div className="top-banner">
                    <a href="http://www.infolinks.com/join-us?aid=3257254" target="_blank" rel="noopener noreferrer nofollow">
                        <img className="desktop-ad" src="https://resources.infolinks.com/static/referral-banners/728X90/Creative2-728X90.jpg" alt="ad" />
                    </a>
                    <a href="http://www.infolinks.com/join-us?aid=3257254" target="_blank" rel="noopener noreferrer nofollow">
                        <img className="mobile-ad" src="https://resources.infolinks.com/static/referral-banners/320X50/Creative2-320X50.jpg" alt="ad" />
                    </a>
                </div>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="masonry-grid masonry"
                    columnClassName="masonry-grid_column"
                >
                    {posts.map( ( node, index) => {
                        /*if (index>0 && index%7===0) {
                            return [
                                <Article key={`list-add-${node.id}`} node={node} indexed={indexed} uris={uris} type="ad" />,
                                <Article key={`list-${node.id}`} node={node} indexed={indexed} uris={uris}  />
                            ];
                        }*/
                        return <Article key={`list-${node.id}`} node={node} indexed={indexed} uris={uris} />
                    })}
                </Masonry>
                <Pagination 
                    uri={`${uris[section]}${subsection ? `/${subsection}` : ''}`}
                    currentPage={page} 
                    totalItems={totalItems}
                    pageSize={postsPerPage}
                    maxPages={paginationItems}
                />
                <div className="bottom-banner">
                    <input type="hidden" name="IL_IN_ARTICLE" />
                    {/*<img className="desktop-ad" src="https://lh3.googleusercontent.com/Sh7SH08FZ6pVcq7dxVgc3FmuW0wLFM-0cvYPRYxDFeB4vZ0GLS5qibRWO-4KXxl_tKo=w728-h90" alt="ad" />
                    <img className="mobile-ad" src="https://upload.wikimedia.org/wikipedia/commons/2/24/Ad-MediumRectangle-300x250.jpg" alt="ad" />*/}
                </div>
            </div>
        </Layout>
    );
};

ListTemplate.propTypes = {
    categories: PropTypes.object
}

ListTemplate.defaultProps = {
    categories: {}
}

export default ListTemplate;