import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby";

const paginate = (totalItems, currentPage, pageSize, maxPages) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}

const propTypes = {
    totalItems: PropTypes.number.isRequired,
    initialPage: PropTypes.number,
    currentPage: PropTypes.number,
    uri: PropTypes.string,
    pageSize: PropTypes.number,
    maxPages: PropTypes.number,
    labels: PropTypes.object,
    disableDefaultStyles: PropTypes.bool
}

const defaultProps = {
    initialPage: 1,
    currentPage: 1,
    pageSize: 10,
    maxPages: 10,
    uri: '/',
    disableDefaultStyles: false,
    labels: {
        first: 'Inicio',
        last: 'Último',
        previous: 'Anterior',
        next: 'Siguiente'
    }
}

class Pagination extends React.Component {

    constructor(props) {
        super(props);
        this.styles = {};

        const { totalItems, currentPage, pageSize, maxPages } = this.props;
        this.pager = paginate(totalItems, currentPage, pageSize, maxPages);
    }

    render() {
        var labels = this.props.labels;

        if (!this.pager.pages || this.pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }

        let firstButton = '';
        let previousButton = '';
        if (this.pager.currentPage !== 1) {
            firstButton = <li>
                <Link className={`pgn__num first`} to={(this.props.uri.length ? `${this.props.uri}/` : '/')}>{labels.first}</Link>
            </li>;
            previousButton = <li>
                <Link className={`pgn__prev`} to={this.pager.currentPage-1 === 1 ? (this.props.uri.length ? `${this.props.uri}/` : '/') : `${this.props.uri}/${this.pager.currentPage - 1}/`}>{labels.previous}</Link>
            </li>;
        }

        let lastButton = '';
        let nextButton = '';
        if (this.pager.currentPage !== this.pager.totalPages) {
            lastButton = <li>
                <Link className={`pgn__num last`} to={`${this.props.uri}/${this.pager.totalPages}/`}>{labels.last}</Link>
            </li>;
            nextButton = <li>
                <Link className={`pgn__next`} to={`${this.props.uri}/${this.pager.currentPage + 1}/`}>{labels.next}</Link>
            </li>;
        }

        return (
            <nav className="pgn">
                <ul>
                    {firstButton}
                    {previousButton}
                    {this.pager.pages.map((page, index) =>
                        <li key={index}>
                            <Link className={`pgn__num ${this.pager.currentPage === page ? 'current' : ''}`} to={ page === 1 ? (this.props.uri.length ? this.props.uri : '/') : `${this.props.uri}/${page}/`}>{page}</Link>
                        </li>
                    )}
                    {nextButton}
                    {lastButton}
                </ul>
            </nav>
        );
    }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;